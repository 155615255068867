<template>
    <div class="fieldset radio">
        <div class="radio-wrapper" v-for="option in options">
            <label>
                <input
                    type="radio"
                    v-model="option.value"
                    :value="option.value"
                    @input="updateValue($event.target.value)"
                    :name="name"
                    :ref="option.value"
                />
                <span class="checkmark"></span>
                {{ option.label }}
            </label>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'RadioField',

        props: [
            'value',
            'options',
            'name',
            'preselect'
        ],

        mounted() {
            if(this.preselect != null){
                this.$refs[this.options[this.preselect].value][0].click()
            }
        },

        methods: {
            updateValue(value) {
                this.$emit('input', value.length > 0 ? value : null)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .radio {
        justify-content: flex-start;

        .radio-wrapper {
            margin-right: 40px;
            margin-bottom: 20px;
            position: relative;

            label {
                cursor: pointer;
            }

            input {
                opacity: 0;
                margin-right: 20px;

                &:checked {
                    ~ .checkmark {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            .checkmark {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 20px;
                width: 20px;
                border: 1px solid $timberGreen;

                &:after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    bottom: 5px;
                    left: 5px;
                    background: $timberGreen;
                    opacity: 0;
                }
            }
        }
    }
</style>
